<template>
  <div class="app-form">
    <el-form
      ref="formFmhbase"
      :model="form"
      label-width="100px"
      label-position="right"
      :rules="rules"
      :validate-on-rule-change="false"
    >
      <!-- CHAMPS -->
      <el-row>
        <el-col :span="12">
          <!-- Titre -->
          <el-form-item label="Titre" prop="id_fpost_titre">
            <el-select
              ref="titre"
              v-model="form.id_fpost_titre"
              clearable
              filterable
              placeholder="Choisissez un titre"
            >
              <el-option
                v-for="item in titreOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- Précisions -->
          <el-form-item label="Précisions" prop="precisions">
            <el-input v-model="form.precisions"></el-input>
          </el-form-item>
          <!-- Statut -->
          <el-form-item prop="id_statut" label="Statut">
            <el-radio-group
              v-model="form.id_statut"
              size="small"
              style="width: 280px"
              :disabled="true"
            >
              <el-radio-button :label="1">prévue</el-radio-button>
              <el-radio-button :label="2">en cours</el-radio-button>
              <el-radio-button :label="3">terminée</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <!-- Année -->
          <el-form-item label="Année" prop="annee">
            <el-select
              ref="annee"
              size="small"
              v-model="form.annee"
              clearable
              filterable
              placeholder="Choisissez une annnée"
            >
              <el-option
                v-for="item in anneeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- -->
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      titres: [],
      type: null,
      desactive: true,
      rules: {
        id_med: [
          {
            required: true,
            message: "Ce champ est obligatoire",
            trigger: "change",
          },
        ],
        id_fpost_titre: [
          {
            required: true,
            message: "Ce champ est obligatoire",
            trigger: "change",
          },
        ],
        annee: [
          {
            required: true,
            message: "Ce champ est obligatoire",
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    form: {
      // https://vuex.vuejs.org/guide/forms.html#two-way-computed-property
      get() {
        return this.$store.state.fpg.fmhbase;
      },
      set(form) {
        this.$store.commit("fpg/setFmhbase", form);
      },
    },
    titreOptions() {
      return this.$store.getters["fpgtit/afchospFmhBaseTitreSelect"];
    },
    anneeOptions() {
      return this.$store.getters["fpg/optAnnee"];
    },
    fpgTitresIsLoaded() {
      return (
        this.$store.state.fpg.fpostgradLoadStatus == 2 &&
        this.$store.state.fpgtit.titresLoadStatus == 2
      );
    },
    idTitre() {
      return this.form.id_fpost_titre;
    },
  },
};
</script>
