<template>
  <div>
    <h1>Formation post-graduée</h1>
    <el-row style="width: 820px" v-loading="fpostgradLoadStatus == 1">
      <el-col :span="12">
        <form-fpostgrad
          :ismeddetail="ismeddetail"
          :isedit="true"
          ref="formFpost"
        >
        </form-fpostgrad>
      </el-col>
      <el-col :span="12" v-if="fpostgradLoadStatus == 2">
        <subform-afc-hosp v-if="afchospShow" ref="subFormAfcHosp" />
        <subform-afc-xhosp v-if="afcxhospShow" ref="subFormAfcXhosp" />
        <subform-fmh-mig v-if="fmhmigShow" ref="subFormFmhMig" />
      </el-col>
    </el-row>
    <el-divider></el-divider>
    <div style="margin-top: 8px">
      <el-button @click="onBack">Retour</el-button>
      <el-button type="primary" @click="onSubmit">Enregistrer</el-button>
      <el-button @click="onCancel">Annuler</el-button>
    </div>
  </div>
</template>
<script>
import FormFpostgrad from "@/components/rh/ui/form/FormFpostgrad";
import SubformFmhMig from "@/components/rh/ui/form/SubFormFmhMig";
import SubformAfcHosp from "@/components/rh/ui/form/SubFormAfcHosp";
import SubformAfcXhosp from "@/components/rh/ui/form/SubFormAfcXhosp";
import { compareFpostgrad, trimStringInObj } from "@/fonctions";

export default {
  components: {
    "form-fpostgrad": FormFpostgrad,
    "subform-fmh-mig": SubformFmhMig,
    "subform-afc-hosp": SubformAfcHosp,
    "subform-afc-xhosp": SubformAfcXhosp,
  },

  data() {
    return {
      loadCount: 0,
      titresAvecChecklist: [71, 1, 2],
    };
  },
  props: {
    ismeddetail: Boolean,
    routetolist: Object,
  },
  created() {
    this.$store.dispatch("fpg/loadFpostgrad", this.idFpost);
  },
  mounted() {
    this.afficheData();
  },
  computed: {
    idFpost() {
      // récupère le id le la fpostgrad dans l'url
      return String(this.$route.params.idfpg);
    },
    fpostgradLoadStatus() {
      return this.$store.state.fpg.fpostgradLoadStatus;
    },
    fpostgradUpdateStatus() {
      // 1 = action en cours, 2 = succès, 3 = échec
      return this.$store.state.fpg.fpostgradUpdateStatus;
    },
    fpostgradLastCreatedId() {
      return this.$store.state.fpg.fpostgradLastCreatedId;
    },
    fmhmigShow() {
      const fpg = this.$store.state.fpg.fpostgrad;
      if (fpg.id_fpost_titre == 71 && fpg.use_checklist == 1) {
        const mig = fpg.fmhmig;
        const urg1sur6 = Object.keys(mig).filter((k) => k.startsWith("urg_"));
        const autres = Object.keys(mig)
          .filter((k) => !urg1sur6.includes(k))
          .filter(
            (k) => !["id", "id_fpost", "updated_at", "created_at"].includes(k)
          );

        const OKautres = autres.length;
        let Checkedautres = 0;
        let Checkedurg1sur6 = 0;

        // boucle sur les keys de l'object fmhmig pour vérifier que la checklit est complète
        autres.forEach((k) => (mig[k] > 0 ? ++Checkedautres : Checkedautres));
        urg1sur6.forEach((k) =>
          mig[k] > 0 ? ++Checkedurg1sur6 : Checkedurg1sur6
        );

        // si checklist complète passe le status à 'terminée' et si incomplète, le repasse à 'en cours' et si aucun cochet passe à 'prévue'
        if (Checkedautres == OKautres && Checkedurg1sur6 > 0) {
          fpg.id_statut = 3;
        } else if (Checkedautres > 0 || Checkedurg1sur6 > 0) {
          fpg.id_statut = 2;
          fpg.annee = null;
        } else {
          fpg.id_statut = 1;
          fpg.annee = null;
        }
        this.firstLoadWithChecklist();
        return !(
          fpg.id_statut == 3 &&
          (Checkedautres != OKautres || Checkedurg1sur6 == 0)
        );
      } else {
        return false;
      }
    },
    afcxhospShow() {
      const fpg = this.$store.state.fpg.fpostgrad;
      if (fpg.id_fpost_titre == 1 && fpg.use_checklist == 1) {
        const xhosp = fpg.afcxhosp;
        const fields = Object.keys(xhosp).filter(
          (k) => !["id", "id_fpost", "created_at", "updated_at"].includes(k)
        );
        const total = fields.length;
        let checked = 0;
        // boucle sur les keys de l'object afcxhosp pour vérifier que la checklit est complète
        fields.forEach((k) => (xhosp[k] > 0 ? ++checked : checked));
        // si checklist complète passe le status à 'terminée' et si incomplète, le repasse à 'en cours' et si aucun cochet passe à 'prévue'
        if (total == checked) {
          fpg.id_statut = 3;
        } else if (checked > 0) {
          fpg.id_statut = 2;
          fpg.annee = null;
        } else {
          fpg.id_statut = 1;
          fpg.annee = null;
        }
        this.firstLoadWithChecklist();
        return !(fpg.id_statut == 3 && total != checked);
      } else {
        return false;
      }
    },
    afchospShow() {
      const fpg = this.$store.state.fpg.fpostgrad;
      if (fpg.id_fpost_titre == 2 && fpg.use_checklist == 1) {
        const hosp = fpg.afchosp;
        const cours2sur3 = Object.keys(hosp).filter((k) =>
          k.startsWith("urg_")
        );
        const cata1sur4 = Object.keys(hosp).filter((k) =>
          k.startsWith("cata_")
        );
        const us1sur3 = Object.keys(hosp).filter((k) => k.startsWith("us_"));
        const autres = [
          "id_fmh_base",
          "id_fpost_site_1",
          "id_fpost_site_2",
          "logbook_75cas12",
          "logbook_5minicexdops",
          "logbook_3entretiens",
          "congres_smuss",
          "stage_xhosp",
          "examen",
        ];
        const OKautres = autres.length;
        let Checkedcours2sur3 = 0;
        let Checkedcata1sur4 = 0;
        let Checkedus1sur3 = 0;
        let Checkedautres = 0;
        // boucles pour récupérer le nombre d'items cochés
        cours2sur3.forEach((k) =>
          hosp[k] > 0 ? ++Checkedcours2sur3 : Checkedcours2sur3
        );
        cata1sur4.forEach((k) =>
          hosp[k] > 0 ? ++Checkedcata1sur4 : Checkedcata1sur4
        );
        us1sur3.forEach((k) =>
          hosp[k] > 0 ? ++Checkedus1sur3 : Checkedus1sur3
        );
        autres.forEach((k) => (hosp[k] > 0 ? ++Checkedautres : Checkedautres));

        // si checklist complète passe le status à 'terminée' et si incomplète, le repasse à 'en cours' et si aucun cochet passe à 'prévue'
        if (
          Checkedcours2sur3 > 1 &&
          Checkedcata1sur4 > 0 &&
          Checkedus1sur3 > 0 &&
          Checkedautres == OKautres
        ) {
          fpg.id_statut = 3;
        } else if (
          Checkedcours2sur3 > 0 ||
          Checkedcata1sur4 > 0 ||
          Checkedus1sur3 > 0 ||
          Checkedautres > 0
        ) {
          fpg.id_statut = 2;
          fpg.annee = null;
        } else {
          fpg.id_statut = 1;
          fpg.annee = null;
        }

        this.firstLoadWithChecklist();

        return !(
          fpg.id_statut == 3 &&
          (Checkedautres != OKautres ||
            Checkedcours2sur3 < 2 ||
            Checkedcata1sur4 == 0 ||
            Checkedus1sur3 == 0)
        );
      } else {
        return false;
      }
    },
    // Si édition des fontinues dans les chekclist
    fcontinueUpdateStatus() {
      // 1 = action en cours, 2 = succès, 3 = échec
      return this.$store.state.fc.fcontinueUpdateStatus;
    },
    fcontinueCreateStatus() {
      // 1 = action en cours, 2 = succès, 3 = échec
      return this.$store.state.fc.fcontinueCreateStatus;
    },
    fcontinueDeleteStatus() {
      // 1 = action en cours, 2 = succès, 3 = échec
      return this.$store.state.fc.fcontinueDeleteStatus;
    },
  },
  methods: {
    afficheData() {
      if (this.fpostgradLoadStatus == 2) {
        // charge les données de la fpostgrad
        const fpg = this.$store.state.fpg.fpostgrad;
        // garde les données chargées dans le localStorage pour les comparée avec les modifs
        localStorage.fpgLoaded = JSON.stringify(fpg);
      } else if (this.fpostgradLoadStatus == 3) {
        // message échec
        this.$message.error({ message: "Echec du chargement des données !" });
      }
    },
    // si 1ère ouverture après création enregistre auto à l'ouverture pour sauvegarder les formations continues dans la checklist associée
    // s'execute dans fmhmigShow(), afcxhospShow(), afchospShow() (que si use_checklist == 1)
    firstLoadWithChecklist() {
      const fpg = this.$store.state.fpg.fpostgrad;
      if (fpg.id == this.fpostgradLastCreatedId) {
        //maj l'enregistrement
        this.$store.dispatch("fpg/updateFpostgrad", fpg);
      }
    },
    onSubmit() {
      this.$refs.formFpost.$refs.formFpostgrad.validate((valid) => {
        if (valid) {
          let fpgdata = this.$store.state.fpg.fpostgrad;
          // enlever les espaces avant et après dans les string
          trimStringInObj(fpgdata);
          const fpgInBD = this.$store.getters["fpg/FpostgradInBD"];
          const id = this.idFpost;
          // compare avec les données avant modifcation et pour l'enregistrement
          // en supprimant les "updated_at" du string pour comparer seulemnt les changements effectifs
          if (compareFpostgrad(localStorage.fpgLoaded, fpgdata)) {
            // si pas de modifications
            this.$message({
              message: "Les données n'ont pas été modifiées",
            });
            // vérifie si une fpostgrad idendique existe déjà pour le médecin
          } else if (
            fpgInBD.filter(
              (m) =>
                m[0] != id &&
                m[1] == fpgdata.id_med &&
                m[2] == fpgdata.id_fpost_titre
            ).length > 0
          ) {
            this.$alert(
              "Ce titre a déjà été enregistré pour ce médecin",
              "Attention",
              {
                confirmButtonText: "Fermer",
                dangerouslyUseHTMLString: true,
              }
            );
            return false;
          } else {
            // si plus d'erreur
            // efface l'année si statut != 3 'terminé'
            fpgdata.annee = fpgdata.id_statut != 3 ? null : fpgdata.annee;
            //maj l'enregistrement
            this.$store.dispatch("fpg/updateFpostgrad", fpgdata);
          }
        } else {
          return false;
        }
      });
    },
    onCancel() {
      // compare avec les données avant modifcation et pour l'enregistrement
      // en supprimant les "updated_at" du string pour comparer seulemnt les changements effectifs
      const fpg = this.$store.state.fpg.fpostgrad;
      if (!compareFpostgrad(localStorage.fpgLoaded, fpg)) {
        // si modifications
        let dataObj = JSON.parse(localStorage.fpgLoaded);
        this.$store.commit("fpg/setFpostgrad", dataObj);
        this.$message({
          message: "Les modifications ont été annulées",
          type: "warning",
        });
      } else {
        this.$message({
          message: "Les données n'ont pas été modifiées",
        });
      }
    },
    onBack() {
      // compare avec les données avant modifcation et pour l'enregistrement
      // en supprimant les "updated_at" du string pour comparer seulemnt les changements effectifs
      const fpg = this.$store.state.fpg.fpostgrad;
      if (compareFpostgrad(localStorage.fpgLoaded, fpg)) {
        // si pas de modifications
        // retourne à la liste des formations post-graduées
        this.$router.push(this.routetolist);
      } else {
        this.$confirm(
          "Continuer sans enregistrer les modification ?",
          "Attention !",
          {
            confirmButtonText: "Continuer",
            cancelButtonText: "Annuler",
            type: "warning",
          }
        ).then(() => {
          // retourne à la liste des formations post-graduées
          this.$router.push(this.routetolist);
        });
      }
    },
  },
  watch: {
    fpostgradLoadStatus() {
      // ne lance l'affichage des données que si toutes les données sont chargées
      // lorsqu'on charge directement la page
      this.afficheData();
    },
    fpostgradUpdateStatus() {
      // gère les messages et la navigation après soumission du formulaire
      if (this.fpostgradUpdateStatus == 2) {
        const fpg = this.$store.state.fpg.fpostgrad;
        if (fpg.id != this.fpostgradLastCreatedId) {
          // message succès
          this.$message({
            message: "Les modifications ont été enregistrées.",
            type: "success",
          });
        } else {
          // remet à 0 l'id de la dernière fpg créée
          this.$store.commit("fpg/setFpostgradLastCreatedId", 0);
        }
        // place les données sauvegardée dans le localStorage (nouvelles données initiales)
        localStorage.fpgLoaded = JSON.stringify(fpg);
      } else if (this.fpostgradUpdateStatus == 3) {
        // message échec
        this.$message.error({ message: "Echec de l'enregistrement !" });
      }
    },
    // Messages si édite fcontinue dans checklist
    fcontinueUpdateStatus() {
      // gère les messages après soumission du formulaire
      if (this.fcontinueUpdateStatus == 2) {
        // message succès
        this.$message({
          message: "Les modifications ont été enregistrées.",
          type: "success",
        });
      } else if (this.fcontinueUpdateStatus == 3) {
        // message échec
        this.$message.error({ message: "Echec de l'enregistrement !" });
      }
    },
    fcontinueCreateStatus() {
      // gère les messages après soumission du formulaire
      if (this.fcontinueCreateStatus == 2) {
        // message succès
        this.$message({
          message: "La formation a été ajoutée à la base de données",
          type: "success",
        });
      } else if (this.fcontinueCreateStatus == 3) {
        // message échec
        this.$message.error({ message: "Echec de l'enregistrement !" });
      }
    },
    fcontinueDeleteStatus() {
      // gère les messages lors de la suppression d'un enregistrement
      if (this.fcontinueDeleteStatus == 2) {
        // message succès
        this.$message({
          message: "La formation a été supprimée de la base de données",
          type: "warning",
        });
      } else if (this.fcontinueDeleteStatus == 3) {
        // message échec
        this.$message.error({
          message: "Echec de la suppression de l'enregistrement !",
        });
      }
    },
  },
};
</script>
