<template>
  <el-dialog
    title="Ajouter une FMH de base terminée"
    :visible.sync="dialogShowFMHCreate"
    width="460px"
  >
    <form-fmhbase ref="formFmh" />
    <span slot="footer" class="dialog-footer">
      <el-button
        size="mini"
        type="primary"
        :disabled="isSaving"
        @click="onSubmit"
        >Enregistrer</el-button
      >
      <el-button size="mini" @click="onCancel">Annuler</el-button>
    </span>
  </el-dialog>
</template>
<script>
import FormFmhbase from "@/components/rh/ui/form/FormFmhbase";
import { trimStringInObj } from "@/fonctions";

export default {
  components: { "form-fmhbase": FormFmhbase },
  data() {
    return {
      isSaving: false,
    };
  },
  computed: {
    dialogShowFMHCreate: {
      // https://vuex.vuejs.org/guide/forms.html#two-way-computed-property
      get() {
        return this.$store.state.comp.dialogShowFMHCreate;
      },
      set(dialogShowFMHCreate) {
        this.$store.commit("comp/setDialogShowFMHCreate", dialogShowFMHCreate);
      },
    },
    fpostgradCreateStatus() {
      // 1 = action en cours, 2 = succès, 3 = échec
      return this.$store.state.fpg.fpostgradCreateStatus;
    },
  },
  methods: {
    onSubmit() {
      this.$refs.formFmh.$refs.formFmhbase.validate((valid) => {
        if (valid) {
          var fmhdata = this.$store.state.fpg.fmhbase;

          // enlever les espaces avant et après dans les string
          trimStringInObj(fmhdata);

          // vérifie si le titre existe déjà pour le médecin
          const fpgInBD = this.$store.getters["fpg/FpostgradInBD"];
          if (
            fpgInBD.filter(
              (m) => m[1] == fmhdata.id_med && m[2] == fmhdata.id_fpost_titre
            ).length > 0
          ) {
            this.$alert(
              'Ce titre a déjà été enregistré pour ce médecin. Au besoin, passer son statut à "terminé" pour qu\'il apparaisse dans la checklist.',
              "Attention",
              {
                confirmButtonText: "Fermer",
                dangerouslyUseHTMLString: true,
              }
            );
            // ferme la boîte de dialogue
            this.dialogShowFMHCreate = false;

            return false;
          } else {
            // si plus d'erreur lance la création du nouvel enregistrement
            this.$store.dispatch("fpg/createFpostgrad", fmhdata);
          }
        } else {
          return false;
        }
      });
    },
    onCancel() {
      // ferme la boîte de dialogue
      this.dialogShowFMHCreate = false;
      // reset le form
      this.$refs.formFmh.$refs.formFmhbase.resetFields();
    },
  },
  watch: {
    fpostgradCreateStatus() {
      // gère les messages et la navigation après soumission du formulaire
      if (this.fpostgradCreateStatus == 2) {
        // message succès
        this.$message({
          message: "La formation a été ajouté-e à la base de donnée",
          type: "success",
        });
        // ferme la boîte de dialogue
        this.dialogShowFMHCreate = false;
      } else if (this.fpostgradCreateStatus == 3) {
        this.isSaving = false;
        // message échec
        this.$message.error({ message: "Echec de l'enregistrement !" });
      } else if (this.fpostgradCreateStatus == 1) {
        this.isSaving = true;
      } else if (this.fpostgradCreateStatus == 0) {
        this.isSaving = false;
      }
    },
  },
};
</script>
