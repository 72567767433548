<template>
  <div>
    <h5>Checklist:</h5>
    <el-card class="checklist-box-card">
      <!-- Périodes -->
      <div slot="header" class="clearfix">
        <h5>FMH médecine interne générale</h5>
      </div>
      <el-form
        ref="subFormChecklist"
        :model="form.fmhmig"
        label-position="right"
      >
        <el-form-item prop="mig3ans">
          <el-checkbox
            v-model="form.fmhmig.mig3ans"
            label="3 ans de médecine interne générale :"
            :true-label="1"
            :false-label="0"
          >
          </el-checkbox>
        </el-form-item>
        <div class="subitem">
          <el-form-item prop="mig3ans_2centres">
            <el-checkbox
              v-model="form.fmhmig.mig3ans_2centres"
              label="2 centres"
              :true-label="1"
              :false-label="0"
            >
            </el-checkbox>
          </el-form-item>
          <el-form-item prop="mig3ans_6mois_ambu">
            <el-checkbox
              v-model="form.fmhmig.mig3ans_6mois_ambu"
              label="6 mois ambulatoires"
              :true-label="1"
              :false-label="0"
            >
            </el-checkbox>
          </el-form-item>
          <el-form-item prop="mig3ans_3mois_urg">
            <el-checkbox
              v-model="form.fmhmig.mig3ans_3mois_urg"
              label="3 mois urgences"
              :true-label="1"
              :false-label="0"
            >
            </el-checkbox>
          </el-form-item>
          <el-form-item prop="mig3ans_1an_cata">
            <el-checkbox
              v-model="form.fmhmig.mig3ans_1an_cata"
              label="1 an catégorie A"
              :true-label="1"
              :false-label="0"
            >
            </el-checkbox>
          </el-form-item>
        </div>
        <el-form-item prop="id_autredisc_1an_1">
          <span slot="label"
            ><i v-html="autreDisc1IconStatus"></i>1 an dans une autre
            discipline</span
          >
          <el-select
            v-model="form.fmhmig.id_autredisc_1an_1"
            clearable
            filterable
            placeholder="Choisissez une discipline"
            style="width: 260px"
          >
            <el-option
              v-for="item in disciplineOptions1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="id_autredisc_1an_2">
          <span slot="label"
            ><i v-html="autreDisc2IconStatus"></i>1 an dans une autre
            discipline</span
          >
          <el-select
            v-model="form.fmhmig.id_autredisc_1an_2"
            clearable
            filterable
            placeholder="Choisissez une discipline"
            style="width: 260px"
          >
            <el-option
              v-for="item in disciplineOptions2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="examen">
          <el-checkbox
            v-model="form.fmhmig.examen"
            label="Examen"
            :true-label="1"
            :false-label="0"
          >
          </el-checkbox>
        </el-form-item>
        <el-form-item prop="publication">
          <el-checkbox
            v-model="form.fmhmig.publication"
            label="Publication"
            :true-label="1"
            :false-label="0"
          >
          </el-checkbox>
        </el-form-item>
        <el-form-item>
          <span slot="label"
            ><i v-html="coursurgStatus"></i>1 cours de médecine d'urgence
            :</span
          >
        </el-form-item>
        <div class="subitem">
          <!-- SMUSS XHOPS -->
          <el-form-item>
            <span slot="label"
              ><i v-html="urg_smussxhospStatus"></i>SSMUS - Cours de médecine
              d'urgence préhosp.</span
            >
            <span class="checklist-fcontinue-valid">{{
              getCoursAnMois("urg_smussxhosp")
            }}</span>
            <el-tooltip content="Editer">
              <el-button type="text" @click="onOpenDialog('urg_smussxhosp')"
                ><i class="el-icon-edit"></i
              ></el-button>
            </el-tooltip>
          </el-form-item>
          <!-- SMUSS Méd.de garde -->
          <el-form-item>
            <span slot="label"
              ><i v-html="urg_smussgardeStatus"></i>SSMUS - Cours pour médecins
              de garde</span
            >
            <span class="checklist-fcontinue-valid">{{
              getCoursAnMois("urg_smussgarde")
            }}</span>
            <el-tooltip content="Editer">
              <el-button type="text" @click="onOpenDialog('urg_smussgarde')"
                ><i class="el-icon-edit"></i
              ></el-button>
            </el-tooltip>
          </el-form-item>
          <!-- cours SVM -->
          <el-form-item prop="urg_svm">
            <span slot="label"
              ><i v-html="urg_svmStatus"></i>SVM - Cours pour Médecins de
              premier secours</span
            >
            <span class="checklist-fcontinue-valid">{{
              getCoursAnMois("urg_svm")
            }}</span>
            <el-tooltip content="Editer">
              <el-button type="text" @click="onOpenDialog('urg_svm')"
                ><i class="el-icon-edit"></i
              ></el-button>
            </el-tooltip>
          </el-form-item>
          <!-- ACLS -->
          <el-form-item prop="urg_acls">
            <span slot="label"><i v-html="urg_aclsStatus"></i>ACLS</span>
            <span class="checklist-fcontinue-valid">{{
              getCoursAnMois("urg_acls")
            }}</span>
            <el-tooltip content="Editer">
              <el-button type="text" @click="onOpenDialog('urg_acls')"
                ><i class="el-icon-edit"></i
              ></el-button>
            </el-tooltip>
          </el-form-item>
          <!-- ALS MED -->
          <el-form-item prop="urg_alsmed">
            <span slot="label"><i v-html="urg_alsmedStatus"></i>ALS MED</span>
            <span class="checklist-fcontinue-valid">{{
              getCoursAnMois("urg_alsmed")
            }}</span>
            <el-tooltip content="Editer">
              <el-button type="text" @click="onOpenDialog('urg_alsmed')"
                ><i class="el-icon-edit"></i
              ></el-button>
            </el-tooltip>
          </el-form-item>
          <!-- ALS TRAUMA -->
          <el-form-item prop="urg_alstrauma">
            <span slot="label"
              ><i v-html="urg_alstraumaStatus"></i>ALS TRAUMA</span
            >
            <span class="checklist-fcontinue-valid">{{
              getCoursAnMois("urg_alstrauma")
            }}</span>
            <el-tooltip content="Editer">
              <el-button type="text" @click="onOpenDialog('urg_alstrauma')"
                ><i class="el-icon-edit"></i
              ></el-button>
            </el-tooltip>
          </el-form-item>
        </div>
        <el-form-item prop="fpg_24credits">
          <el-checkbox
            v-model="form.fmhmig.fpg_24credits"
            label="24 crédits FPG"
            :true-label="1"
            :false-label="0"
          >
          </el-checkbox>
        </el-form-item>
      </el-form>
    </el-card>
    <!-- boîte de dialogue pour éditer les formations continues -->
    <dialogformfpg-fcontinue :dialogtitre="dialogTitre" />
  </div>
</template>
<script>
import {
  checklistIcon,
  fcontinueMajChecklist,
  objGetNested,
} from "@/fonctions";
import DialogformfpgFcontinue from "@/components/rh/dialogbox/DialogformfpgFcontinue";

export default {
  components: { "dialogformfpg-fcontinue": DialogformfpgFcontinue },

  data() {
    return {
      coursurg1sur6OK: false,
      dialogTitre: "",
    };
  },
  methods: {
    getCoursAnMois(cours) {
      // évite l'erreur : "Cannot read property 'annee_mois' of undefined"
      return objGetNested(this.fcontinues, [cours, "annee_mois"]);
    },
    onOpenDialog(titre) {
      const fc = this.fcontinues[titre];
      const fcdata = {
        id: fc.id,
        id_med: fc.id_med,
        id_fcont_titre: fc.id_fcont_titre,
        autre_prec: fc.autre_prec,
        annee: fc.annee,
        mois: fc.mois,
      };
      this.dialogTitre = fc.titre;
      this.dialogShowFcAdd = true;
      localStorage.fcLoaded = JSON.stringify(fcdata);
      this.$store.commit("fc/setFcontinue", fcdata);
    },
    changeStatut(item, val) {
      item = val;
    },
    changeStatutCours(fcontinuesOcc) {
      // maj le statut des cours (fcontinues) dans la checklist
      Object.keys(fcontinuesOcc).forEach((key) => {
        if (objGetNested(fcontinuesOcc, [key, "id"])) {
          this.form.fmhmig[key] = 1;
        } else {
          this.form.fmhmig[key] = 0;
        }
      });
    },
  },
  computed: {
    form: {
      // https://vuex.vuejs.org/guide/forms.html#two-way-computed-property
      get() {
        return this.$store.state.fpg.fpostgrad;
      },
      set(form) {
        this.$store.commit("fpg/setFpostgrad", form);
      },
    },
    dialogShowFcAdd: {
      get() {
        return this.$store.state.comp.dialogShowFcAdd;
      },
      set(dialogShowFcAdd) {
        this.$store.commit("comp/setDialogShowFcAdd", dialogShowFcAdd);
      },
    },
    disciplineOptions1() {
      const opt2Val = this.form.fmhmig.id_autredisc_1an_2;
      let opt = this.$store.getters["fpgtit/fmhmigDisciplineSelect"];

      return opt2Val ? opt.filter((o) => o.value != opt2Val) : opt;
    },
    disciplineOptions2() {
      const opt1Val = this.form.fmhmig.id_autredisc_1an_1;
      let opt = this.$store.getters["fpgtit/fmhmigDisciplineSelect"];

      return opt1Val ? opt.filter((o) => o.value != opt1Val) : opt;
    },
    autreDisc1IconStatus() {
      return checklistIcon(this.form.fmhmig.id_autredisc_1an_1);
    },
    autreDisc2IconStatus() {
      return checklistIcon(this.form.fmhmig.id_autredisc_1an_2);
    },
    coursurgStatus() {
      const c =
        [
          this.form.fmhmig.urg_smussxhosp,
          this.form.fmhmig.urg_smussgarde,
          this.form.fmhmig.urg_svm,
          this.form.fmhmig.urg_acls,
          this.form.fmhmig.urg_alsmed,
          this.form.fmhmig.urg_alstrauma,
        ].reduce((a, c) => a + c) > 0;
      this.changeStatut(this.coursurg1sur6OK, c);
      return checklistIcon(c);
    },
    urg_smussxhospStatus() {
      return checklistIcon(
        this.form.fmhmig.urg_smussxhosp == 1,
        this.coursurg1sur6OK
      );
    },
    urg_smussgardeStatus() {
      return checklistIcon(
        this.form.fmhmig.urg_smussgarde == 1,
        this.coursurg1sur6OK
      );
    },
    urg_svmStatus() {
      return checklistIcon(this.form.fmhmig.urg_svm == 1, this.coursurg1sur6OK);
    },
    urg_aclsStatus() {
      return checklistIcon(
        this.form.fmhmig.urg_acls == 1,
        this.coursurg1sur6OK
      );
    },
    urg_alsmedStatus() {
      return checklistIcon(
        this.form.fmhmig.urg_alsmed == 1,
        this.coursurg1sur6OK
      );
    },
    urg_alstraumaStatus() {
      return checklistIcon(
        this.form.fmhmig.urg_alstrauma == 1,
        this.coursurg1sur6OK
      );
    },
    fcontinues() {
      // retourn un object { nom de la fcontinue : date/'', ... }
      const fcontinuesOcc = fcontinueMajChecklist(this.form.id_med, "fmh_mig");

      this.changeStatutCours(fcontinuesOcc);

      return fcontinuesOcc;
    },
  },
};
</script>
