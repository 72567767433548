<template>
  <div>
    <h5>Checklist:</h5>
    <el-card class="checklist-box-card">
      <div slot="header" class="clearfix">
        <h5>FAI médecine d'urgence hospitalière</h5>
      </div>
      <el-form
        ref="subFormChecklist"
        :model="form.afchosp"
        label-position="right"
      >
        <el-form-item prop="id_fmh_base">
          <span slot="label"><i v-html="fhmBaseStatus"></i>FMH de base</span>
          <el-select
            v-model="form.afchosp.id_fmh_base"
            placeholder="Choisissez une FMH"
            style="width: 330px"
          >
            <el-option
              v-for="item in fmhBaseOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
            <el-option v-show="showAddFMH" value="">
              <el-button type="text" @click="addFMH">
                <i class="el-icon-circle-plus-outline"></i
                ><i> Ajouter une FMH terminée</i>
              </el-button>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <span slot="label"
            ><i v-html="Ans2UrgStatus"></i>2 ans de médecine d'urgence :</span
          >
        </el-form-item>
        <div class="subitem">
          <el-form-item prop="id_fpost_site_1">
            <span slot="label"><i v-html="site1Status"></i>Site 1</span>
            <el-select
              v-model="form.afchosp.id_fpost_site_1"
              clearable
              filterable
              placeholder="Choisissez un site"
            >
              <el-option
                v-for="item in site1Options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="id_fpost_site_2">
            <span slot="label"><i v-html="site2Status"></i>Site 2</span>
            <el-select
              v-model="form.afchosp.id_fpost_site_2"
              clearable
              filterable
              placeholder="Choisissez un site"
            >
              <el-option
                v-for="item in site2Options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
        <!-- 2 cours -->
        <el-form-item>
          <span slot="label"><i v-html="cours2Status"></i>2 cours :</span>
        </el-form-item>
        <div class="subitem">
          <!-- ACLS -->
          <el-form-item>
            <span slot="label"><i v-html="urg_aclsStatus"></i>ACLS</span>
            <span class="checklist-fcontinue-valid">{{
              getCoursAnMois("urg_acls")
            }}</span>
            <el-tooltip content="Editer">
              <el-button type="text" @click="onOpenDialog('urg_acls')"
                ><i class="el-icon-edit"></i
              ></el-button>
            </el-tooltip>
          </el-form-item>
          <!-- ATLS -->
          <el-form-item>
            <span slot="label"><i v-html="urg_atlsStatus"></i>ATLS</span>
            <span class="checklist-fcontinue-valid">{{
              getCoursAnMois("urg_atls")
            }}</span>
            <el-tooltip content="Editer">
              <el-button type="text" @click="onOpenDialog('urg_atls')"
                ><i class="el-icon-edit"></i
              ></el-button>
            </el-tooltip>
          </el-form-item>
          <!-- PALS -->
          <el-form-item>
            <span slot="label"><i v-html="urg_palsStatus"></i>PALS</span>
            <span class="checklist-fcontinue-valid">{{
              getCoursAnMois("urg_pals")
            }}</span>
            <el-tooltip content="Editer">
              <el-button type="text" @click="onOpenDialog('urg_pals')"
                ><i class="el-icon-edit"></i
              ></el-button>
            </el-tooltip>
          </el-form-item>
        </div>
        <!-- cours catastrophe -->
        <el-form-item>
          <span slot="label"
            ><i v-html="cataStatus"></i>1 cours de médecine de catastrophe
            :</span
          >
        </el-form-item>
        <div class="subitem">
          <!-- CEFOCA -->
          <el-form-item>
            <span slot="label"><i v-html="cata_cefocaStatus"></i>CEFOCA 6</span>
            <span class="checklist-fcontinue-valid">{{
              getCoursAnMois("cata_cefoca")
            }}</span>
            <el-tooltip content="Editer">
              <el-button type="text" @click="onOpenDialog('cata_cefoca')"
                ><i class="el-icon-edit"></i
              ></el-button>
            </el-tooltip>
          </el-form-item>
          <!-- SFG H -->
          <el-form-item>
            <span slot="label"><i v-html="cata_sfghStatus"></i>SFG H</span>
            <span class="checklist-fcontinue-valid">{{
              getCoursAnMois("cata_sfgh")
            }}</span>
            <el-tooltip content="Editer">
              <el-button type="text" @click="onOpenDialog('cata_sfgh')"
                ><i class="el-icon-edit"></i
              ></el-button>
            </el-tooltip>
          </el-form-item>
          <!-- EUSEM -->
          <el-form-item>
            <span slot="label"
              ><i v-html="cata_eusemStatus"></i>Cours EUSEM</span
            >
            <span class="checklist-fcontinue-valid">{{
              getCoursAnMois("cata_eusem")
            }}</span>
            <el-tooltip content="Editer">
              <el-button type="text" @click="onOpenDialog('cata_eusem')"
                ><i class="el-icon-edit"></i
              ></el-button>
            </el-tooltip>
          </el-form-item>
          <!-- Autre cours catastrophe -->
          <el-form-item>
            <span slot="label"
              ><i v-html="cata_autreStatus"></i>Autre cours</span
            >
            <span class="checklist-fcontinue-valid">{{
              getCoursAnMois("cata_autre")
            }}</span>
            <el-tooltip content="Editer">
              <el-button type="text" @click="onOpenDialog('cata_autre')"
                ><i class="el-icon-edit"></i
              ></el-button>
            </el-tooltip>
          </el-form-item>
        </div>
        <!-- Cours US -->
        <el-form-item>
          <span slot="label"><i v-html="usStatus"></i>1 cours US :</span>
        </el-form-item>
        <div class="subitem" v-bind:class="{ partielok: us1sur3OK }">
          <!-- Cully -->
          <el-form-item>
            <span slot="label"><i v-html="us_cullyStatus"></i>Cully</span>
            <span class="checklist-fcontinue-valid">{{
              getCoursAnMois("us_cully")
            }}</span>
            <el-tooltip content="Editer">
              <el-button type="text" @click="onOpenDialog('us_cully')"
                ><i class="el-icon-edit"></i
              ></el-button>
            </el-tooltip>
          </el-form-item>
          <!-- Echocardio -->
          <el-form-item>
            <span slot="label"
              ><i v-html="us_echocardioStatus"></i>Echocardio</span
            >
            <span class="checklist-fcontinue-valid">{{
              getCoursAnMois("us_echocardio")
            }}</span>
            <el-tooltip content="Editer">
              <el-button type="text" @click="onOpenDialog('us_echocardio')"
                ><i class="el-icon-edit"></i
              ></el-button>
            </el-tooltip>
          </el-form-item>
          <!-- Autre cours US -->
          <el-form-item>
            <span slot="label"><i v-html="us_autreStatus"></i>Autre cours</span>
            <span class="checklist-fcontinue-valid">{{
              getCoursAnMois("us_autre")
            }}</span>
            <el-tooltip content="Editer">
              <el-button type="text" @click="onOpenDialog('us_autre')"
                ><i class="el-icon-edit"></i
              ></el-button>
            </el-tooltip>
          </el-form-item>
        </div>
        <!-- Logbook -->
        <el-form-item>
          <span slot="label"><i v-html="logbookStatus"></i>Logbook : </span>
        </el-form-item>
        <div class="subitem">
          <el-form-item prop="logbook_75cas12">
            <el-checkbox
              v-model="form.afchosp.logbook_75cas12"
              label="75 cas degré 1 ou 2"
              :true-label="1"
              :false-label="0"
            >
            </el-checkbox>
          </el-form-item>
          <el-form-item prop="logbook_5minicexdops">
            <el-checkbox
              v-model="form.afchosp.logbook_5minicexdops"
              label="5 mini CEX ou DOPS"
              :true-label="1"
              :false-label="0"
            >
            </el-checkbox>
          </el-form-item>
          <el-form-item prop="logbook_3entretiens">
            <el-checkbox
              v-model="form.afchosp.logbook_3entretiens"
              label="3 entretiens"
              :true-label="1"
              :false-label="0"
            >
            </el-checkbox>
          </el-form-item>
        </div>
        <!-- Congres SMUSS -->
        <el-form-item>
          <span slot="label"
            ><i v-html="congres_smussStatus"></i>Congrès SMUSS</span
          >
          <span class="checklist-fcontinue-valid">{{
            getCoursAnMois("congres_smuss")
          }}</span>
          <el-tooltip content="Editer">
            <el-button type="text" @click="onOpenDialog('congres_smuss')"
              ><i class="el-icon-edit"></i
            ></el-button>
          </el-tooltip>
        </el-form-item>
        <!-- Stage xhosp -->
        <el-form-item prop="stage_xhosp">
          <el-checkbox
            v-model="form.afchosp.stage_xhosp"
            label="Stage préhospitalier"
            :true-label="1"
            :false-label="0"
          >
          </el-checkbox>
        </el-form-item>
        <!-- Examen -->
        <el-form-item prop="examen">
          <el-checkbox
            v-model="form.afchosp.examen"
            label="Examen"
            :true-label="1"
            :false-label="0"
          >
          </el-checkbox>
        </el-form-item>
      </el-form>
    </el-card>
    <!-- boîte de dialogue pour éditer les formations continues -->
    <dialogformfpg-fcontinue :dialogtitre="dialogTitre" />
    <!-- boîte de dialogue pour ajouter une FMH de base -->
    <dialogform-fmhbasecreate />
  </div>
</template>
<script>
import {
  checklistIcon,
  fcontinueMajChecklist,
  objGetNested,
} from "@/fonctions";
import DialogformfpgFcontinue from "@/components/rh/dialogbox/DialogformfpgFcontinue";
import DialogformFmhbasecreate from "@/components/rh/dialogbox/DialogformFmhbasecreate";

export default {
  components: {
    "dialogformfpg-fcontinue": DialogformfpgFcontinue,
    "dialogform-fmhbasecreate": DialogformFmhbasecreate,
  },
  data() {
    return {
      cours2sur3OK: false,
      cata1sur4OK: false,
      us1sur3OK: false,
      dialogTitre: "",
    };
  },
  methods: {
    getCoursAnMois(cours) {
      // évite l'erreur : "Cannot read property 'annee_mois' of undefined"
      return objGetNested(this.fcontinues, [cours, "annee_mois"]);
    },
    onOpenDialog(titre) {
      const fc = this.fcontinues[titre];
      const fcdata = {
        id: fc.id,
        id_med: fc.id_med,
        id_fcont_titre: fc.id_fcont_titre,
        autre_prec: fc.autre_prec,
        annee: fc.annee,
        mois: fc.mois,
      };
      this.dialogTitre = fc.titre;
      this.dialogShowFcAdd = true;
      localStorage.fcLoaded = JSON.stringify(fcdata);
      this.$store.commit("fc/setFcontinue", fcdata);
    },
    changeStatut(item, val) {
      item = val;
    },
    changeStatutCours(fcontinuesOcc) {
      // maj le statut des cours (fcontinues) dans la checklist
      Object.keys(fcontinuesOcc).forEach((key) => {
        if (objGetNested(fcontinuesOcc, [key, "id"])) {
          this.form.afchosp[key] = 1;
        } else {
          this.form.afchosp[key] = 0;
        }
      });
    },
    addFMH() {
      this.dialogShowFMHCreate = true;
      this.$store.commit("fpg/resetFmhbase", this.form.id_med);
    },
  },
  computed: {
    form: {
      // https://vuex.vuejs.org/guide/forms.html#two-way-computed-property
      get() {
        return this.$store.state.fpg.fpostgrad;
      },
      set(form) {
        this.$store.commit("fpg/setFpostgrad", form);
      },
    },
    dialogShowFcAdd: {
      get() {
        return this.$store.state.comp.dialogShowFcAdd;
      },
      set(dialogShowFcAdd) {
        this.$store.commit("comp/setDialogShowFcAdd", dialogShowFcAdd);
      },
    },
    dialogShowFMHCreate: {
      get() {
        return this.$store.state.comp.dialogShowFMHCreate;
      },
      set(dialogShowFMHCreate) {
        this.$store.commit("comp/setDialogShowFMHCreate", dialogShowFMHCreate);
      },
    },
    fmhBaseOptions() {
      return this.$store.getters["fpg/afchospFmhSelect"];
    },
    showAddFMH() {
      // si pas de FMH de base montre le bouton pour en ajouter une
      return this.fmhBaseOptions.length == 0;
    },
    site1Options() {
      const opt2Val = this.form.afchosp.id_fpost_site_2;
      let opt = this.$store.getters["fpgsit/afchospSiteSelect"];

      return opt2Val ? opt.filter((o) => o.value != opt2Val) : opt;
    },
    site2Options() {
      const opt1Val = this.form.afchosp.id_fpost_site_1;
      let opt = this.$store.getters["fpgsit/afchospSiteSelect"];

      return opt1Val ? opt.filter((o) => o.value != opt1Val) : opt;
    },
    fhmBaseStatus() {
      return checklistIcon(this.form.afchosp.id_fmh_base);
    },
    site1Status() {
      return checklistIcon(this.form.afchosp.id_fpost_site_1);
    },
    site2Status() {
      return checklistIcon(this.form.afchosp.id_fpost_site_2);
    },
    Ans2UrgStatus() {
      return checklistIcon(
        this.form.afchosp.id_fpost_site_1 && this.form.afchosp.id_fpost_site_2
      );
    },
    cours2Status() {
      // ok si 2 cours sur 3 sélectionnés (somme des valeurs >= 2)
      const c =
        [
          this.form.afchosp.urg_acls,
          this.form.afchosp.urg_atls,
          this.form.afchosp.urg_pals,
        ].reduce((a, c) => a + c) > 1;
      this.changeStatut(this.cours2sur3OK, c);
      return checklistIcon(c);
    },
    urg_aclsStatus() {
      return checklistIcon(this.form.afchosp.urg_acls == 1, this.cours2sur3OK);
    },
    urg_atlsStatus() {
      return checklistIcon(this.form.afchosp.urg_atls == 1, this.cours2sur3OK);
    },
    urg_palsStatus() {
      return checklistIcon(this.form.afchosp.urg_pals == 1, this.cours2sur3OK);
    },
    logbookStatus() {
      // ok si toutes les cases logbook cochées
      const c =
        [
          this.form.afchosp.logbook_75cas12,
          this.form.afchosp.logbook_5minicexdops,
          this.form.afchosp.logbook_3entretiens,
        ].reduce((a, c) => a + c) == 3;
      return checklistIcon(c);
    },
    cataStatus() {
      const c =
        [
          this.form.afchosp.cata_cefoca,
          this.form.afchosp.cata_sfgh,
          this.form.afchosp.cata_eusem,
          this.form.afchosp.cata_autre,
        ].reduce((a, c) => a + c) > 0;
      this.changeStatut(this.cata1sur4OK, c);
      return checklistIcon(c);
    },
    cata_cefocaStatus() {
      return checklistIcon(
        this.form.afchosp.cata_cefoca == 1,
        this.cata1sur4OK
      );
    },
    cata_sfghStatus() {
      return checklistIcon(this.form.afchosp.cata_sfgh == 1, this.cata1sur4OK);
    },
    cata_eusemStatus() {
      return checklistIcon(this.form.afchosp.cata_eusem == 1, this.cata1sur4OK);
    },
    cata_autreStatus() {
      return checklistIcon(this.form.afchosp.cata_autre == 1, this.cata1sur4OK);
    },
    usStatus() {
      const c =
        [
          this.form.afchosp.us_cully,
          this.form.afchosp.us_echocardio,
          this.form.afchosp.us_autre,
        ].reduce((a, c) => a + c) > 0;
      this.changeStatut(this.us1sur3OK, c);
      return checklistIcon(c);
    },
    us_cullyStatus() {
      return checklistIcon(this.form.afchosp.us_cully == 1, this.us1sur3OK);
    },
    us_echocardioStatus() {
      return checklistIcon(
        this.form.afchosp.us_echocardio == 1,
        this.us1sur3OK
      );
    },
    us_autreStatus() {
      return checklistIcon(this.form.afchosp.us_autre == 1, this.us1sur3OK);
    },
    congres_smussStatus() {
      return checklistIcon(this.form.afchosp.congres_smuss == 1);
    },
    fcontinues() {
      // retourne un object { nom de la fcontinue : date/'', ... }
      const fcontinuesOcc = fcontinueMajChecklist(this.form.id_med, "afc_hosp");
      this.changeStatutCours(fcontinuesOcc);

      return fcontinuesOcc;
    },
  },
  watch: {
    fmhBaseOptions() {
      if (!this.form.afchosp.id_fmh_base && this.fmhBaseOptions.length > 0) {
        this.form.afchosp.id_fmh_base = this.fmhBaseOptions[0].value;
      }
    },
  },
};
</script>
