<template>
  <div>
    <h5>Checklist:</h5>
    <el-card class="checklist-box-card">
      <div slot="header" class="clearfix">
        <h5>AFC médecine d'urgence préhospitalière</h5>
      </div>

      <el-form
        ref="subFormChecklist"
        :model="form.afcxhosp"
        label-position="right"
      >
        <el-form-item prop="anesthe_1an">
          <el-checkbox
            v-model="form.afcxhosp.anesthe_1an"
            label="1 an d'anesthésiologie"
            :true-label="1"
            :false-label="0"
          >
          </el-checkbox>
        </el-form-item>
        <el-form-item prop="id_autredisc_1an">
          <span slot="label"
            ><i v-html="autrediscStatus"></i>1 an d'une autre discipline</span
          >
          <el-select
            v-model="form.afcxhosp.id_autredisc_1an"
            clearable
            filterable
            placeholder="Choisissez une discipline"
          >
            <el-option
              v-for="item in autrediscOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="urg_3mois">
          <el-checkbox
            v-model="form.afcxhosp.urg_3mois"
            label="3 mois d'urgences"
            :true-label="1"
            :false-label="0"
          >
          </el-checkbox>
        </el-form-item>
        <el-form-item prop="medintens_3mois">
          <el-checkbox
            v-model="form.afcxhosp.medintens_3mois"
            label="3 mois de médecine intensive"
            :true-label="1"
            :false-label="0"
          >
          </el-checkbox>
        </el-form-item>
        <!-- cours SMUSS xhosp -->
        <el-form-item>
          <span slot="label"
            ><i v-html="urg_smussxhospStatus"></i>SSMUS - Cours de médecine
            d'urgence préhosp.</span
          >
          <span class="checklist-fcontinue-valid">{{
            getCoursAnMois("urg_smussxhosp")
          }}</span>
          <el-tooltip content="Editer">
            <el-button type="text" @click="onOpenDialog('urg_smussxhosp')"
              ><i class="el-icon-edit"></i
            ></el-button>
          </el-tooltip>
        </el-form-item>
        <!-- ACLS -->
        <el-form-item prop="urg_acls">
          <span slot="label"><i v-html="urg_aclsStatus"></i>ACLS</span>
          <span class="checklist-fcontinue-valid">{{
            getCoursAnMois("urg_acls")
          }}</span>
          <el-tooltip content="Editer">
            <el-button type="text" @click="onOpenDialog('urg_acls')"
              ><i class="el-icon-edit"></i
            ></el-button>
          </el-tooltip>
        </el-form-item>
        <!-- PALS -->
        <el-form-item>
          <span slot="label"><i v-html="urg_palsStatus"></i>PALS</span>
          <span class="checklist-fcontinue-valid">{{
            getCoursAnMois("urg_pals")
          }}</span>
          <el-tooltip content="Editer">
            <el-button type="text" @click="onOpenDialog('urg_pals')"
              ><i class="el-icon-edit"></i
            ></el-button>
          </el-tooltip>
        </el-form-item>
        <el-form-item prop="logbook_50naca4">
          <el-checkbox
            v-model="form.afcxhosp.logbook_50naca4"
            :true-label="1"
            :false-label="0"
          >
            <span>Logbook 50 NACA &ge; 4</span>
          </el-checkbox>
        </el-form-item>
      </el-form>
    </el-card>
    <!-- boîte de dialogue pour éditer les formations continues -->
    <dialogformfpg-fcontinue :dialogtitre="dialogTitre" />
  </div>
</template>
<script>
import {
  checklistIcon,
  fcontinueMajChecklist,
  objGetNested,
} from "@/fonctions";
import DialogformfpgFcontinue from "@/components/rh/dialogbox/DialogformfpgFcontinue";

export default {
  components: { "dialogformfpg-fcontinue": DialogformfpgFcontinue },

  data() {
    return {
      dialogTitre: "",
    };
  },
  methods: {
    getCoursAnMois(cours) {
      // évite l'erreur : "Cannot read property 'annee_mois' of undefined"
      return objGetNested(this.fcontinues, [cours, "annee_mois"]);
    },
    onOpenDialog(titre) {
      const fc = this.fcontinues[titre];
      const fcdata = {
        id: fc.id,
        id_med: fc.id_med,
        id_fcont_titre: fc.id_fcont_titre,
        autre_prec: fc.autre_prec,
        annee: fc.annee,
        mois: fc.mois,
      };
      this.dialogTitre = fc.titre;
      this.dialogShowFcAdd = true;
      localStorage.fcLoaded = JSON.stringify(fcdata);
      this.$store.commit("fc/setFcontinue", fcdata);
    },
    changeStatutCours(fcontinuesOcc) {
      // maj le statut des cours (fcontinues) dans la checklist
      Object.keys(fcontinuesOcc).forEach((key) => {
        if (objGetNested(fcontinuesOcc, [key, "id"])) {
          this.form.afcxhosp[key] = 1;
        } else {
          this.form.afcxhosp[key] = 0;
        }
      });
    },
  },
  computed: {
    form: {
      // https://vuex.vuejs.org/guide/forms.html#two-way-computed-property
      get() {
        return this.$store.state.fpg.fpostgrad;
      },
      set(form) {
        this.$store.commit("fpg/setFpostgrad", form);
      },
    },
    dialogShowFcAdd: {
      get() {
        return this.$store.state.comp.dialogShowFcAdd;
      },
      set(dialogShowFcAdd) {
        this.$store.commit("comp/setDialogShowFcAdd", dialogShowFcAdd);
      },
    },
    autrediscOptions() {
      return this.$store.getters["fpgtit/afcxhospAutreDiscSelect"];
    },
    autrediscStatus() {
      return checklistIcon(this.form.afcxhosp.id_autredisc_1an);
    },
    urg_smussxhospStatus() {
      return checklistIcon(this.form.afcxhosp.urg_smussxhosp == 1);
    },
    urg_aclsStatus() {
      return checklistIcon(this.form.afcxhosp.urg_acls == 1);
    },
    urg_palsStatus() {
      return checklistIcon(this.form.afcxhosp.urg_pals == 1);
    },
    fcontinues() {
      // retourne un object { nom de la fcontinue : date/'', ... }
      const fcontinuesOcc = fcontinueMajChecklist(
        this.form.id_med,
        "afc_xhosp"
      );
      this.changeStatutCours(fcontinuesOcc);
      return fcontinuesOcc;
    },
  },
};
</script>
