<template>
  <el-dialog :title="dialogtitre" :visible.sync="dialogShowFcAdd" width="360px">
    <form-fcontinue ref="formFcont" :isdialog="true" />
    <span slot="footer" class="dialog-footer">
      <el-button size="mini" type="primary" @click="fcontOnSubmit"
        >Enregistrer</el-button
      >
      <el-button size="mini" @click="fcontOnCancel">Annuler</el-button>
      <el-button
        size="mini"
        type="danger"
        icon="el-icon-delete"
        @click="fcontOnDelete"
      ></el-button>
    </span>
  </el-dialog>
</template>
<script>
import FormFcontinue from "@/components/rh/ui/form/FormFcontinue";
export default {
  components: { "form-fcontinue": FormFcontinue },
  data() {
    return {};
  },
  props: {
    dialogtitre: String,
  },
  computed: {
    dialogShowFcAdd: {
      // https://vuex.vuejs.org/guide/forms.html#two-way-computed-property
      get() {
        return this.$store.state.comp.dialogShowFcAdd;
      },
      set(dialogShowFcAdd) {
        this.$store.commit("comp/setDialogShowFcAdd", dialogShowFcAdd);
      },
    },
  },
  methods: {
    fcontOnSubmit() {
      this.$refs.formFcont.$refs.formFcontinue.validate((valid) => {
        if (valid) {
          // récupère les données de la boîte de dialogue
          const bd = this.$store.state.fc.fcontinue;
          bd.autre_prec = bd.autre_prec ? bd.autre_prec.trim() : null;
          if (bd.id) {
            // si un id => maj l'enregistrement
            const bdStr = JSON.stringify(bd);
            if (localStorage.fcLoaded == bdStr) {
              // compare les données  avec les données initiales
              this.$message({
                message: "Les données n'ont pas été modifiées",
              });
            } else {
              this.$store.dispatch("fc/updateFcontinue", bd);
            }
          } else {
            // si non, crée un nouveau enregistrement
            this.$store.dispatch("fc/createFcontinue", bd);
          }
          // ferme la boîte de dialogue
          this.dialogShowFcAdd = false;
        }
      });
    },
    fcontOnDelete() {
      // récupère le id de la fcontinue
      const bdId = this.$store.state.fc.fcontinue.id;
      if (bdId) {
        // si un enregistrement à supprimé
        this.$confirm(
          "Vous allez supprimer cette formation continue de la base de données !",
          "Attention !",
          {
            confirmButtonText: "Confirmer",
            cancelButtonText: "Annuler",
            type: "warning",
          }
        ).then(() => {
          this.$store.dispatch("fc/deleteFcontinue", bdId);
          // ferme la boîte de dialogue
          this.dialogShowFcAdd = false;
        });
      } else {
        // ferme la boîte de dialogue
        this.dialogShowFcAdd = false;
      }
    },
    fcontOnCancel() {
      // ferme la boîte de dialogue
      this.dialogShowFcAdd = false;
      // reset le form
      this.$refs.formFcont.$refs.formFcontinue.resetFields();
    },
  },
};
</script>
